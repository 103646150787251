import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'ui-spinner-legacy',
  template:
    '<div class="c-circle-spinner"></div>@if (text) {<div class="spinner--text animation--fade--in-out">{{ text }}</div>}',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpinnerComponent {
  @HostBinding('class') _class = '';

  @Input() text = '';

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('scope')
  set scope(scope: 'page' | 'button' | 'section') {
    this._class = `ui-spinner-legacy--${scope}`;
  }
}
