import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { BlockUIModule } from 'primeng/blockui';
import { BlockUiContainerModule } from '../../primeng/block-ui-container/block-ui-container.module';
import { ButtonComponent } from '../../primeng/button/button.component';
import { ButtonModule } from 'primeng/button';
import { CommonModule } from '@angular/common';
import { DialogComponent } from './dialog.component';
import { LoaderComponent } from '../../non-primeng/loader/loader.component';
import { NgModule } from '@angular/core';
import { DialogModule as PrimeDialogModule } from 'primeng/dialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
  declarations: [DialogComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ProgressSpinnerModule,
    PrimeDialogModule,
    ButtonModule,
    TooltipModule,
    FormsModule,
    LoaderComponent,
    BlockUIModule,
    BlockUiContainerModule,
    ButtonComponent,
  ],
  exports: [DialogComponent],
})
export class DialogModule {}
