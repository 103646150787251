@switch (message.severity) {
    @case (toastType.Success) {
        <div class="ui-toast-icon">
            <svg viewBox="0 0 200 200" class="clock">
                <circle
                    cx="100"
                    cy="100"
                    r="50"
                    fill="none"
                    [@timerChange]="{ value: animationState, params: { duration: animationDuration } }"
                />
            </svg>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                    d="M3.45411 15.5398C2.98926 14.4176 2.75 13.2147 2.75 12C2.75 10.7853 2.98926 9.58244 3.45411 8.46018C3.91897 7.33792 4.60032 6.3182 5.45926 5.45926C6.3182 4.60032 7.33792 3.91897 8.46018 3.45411C9.58244 2.98926 10.7853 2.75 12 2.75C13.2147 2.75 14.4176 2.98926 15.5398 3.45411C16.6621 3.91897 17.6818 4.60032 18.5407 5.45926C19.3997 6.3182 20.081 7.33792 20.5459 8.46018C21.0107 9.58244 21.25 10.7853 21.25 12C21.25 13.2147 21.0107 14.4176 20.5459 15.5398C20.081 16.6621 19.3997 17.6818 18.5407 18.5407C17.6818 19.3997 16.6621 20.081 15.5398 20.5459C14.4176 21.0107 13.2147 21.25 12 21.25C10.7853 21.25 9.58244 21.0107 8.46018 20.5459C7.33792 20.081 6.3182 19.3997 5.45926 18.5407C4.60032 17.6818 3.91897 16.6621 3.45411 15.5398Z"
                    fill="#166534"
                    stroke="#166534"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M9 12L11 14L15 10"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </div>
    }
    @case (toastType.Error) {
        <div class="ui-toast-icon">
            <svg viewBox="0 0 200 200" class="clock">
                <circle
                    cx="100"
                    cy="100"
                    r="50"
                    fill="none"
                    [@timerChange]="{ value: animationState, params: { duration: animationDuration } }"
                />
            </svg>
            <svg width="24" height="24" viewBox="0 0 24 24">
                <path
                    d="M2 12C2 13.3132 2.25866 14.6136 2.7612 15.8268C3.26375 17.0401 4.00035 18.1425 4.92893 19.0711C5.85752 19.9997 6.95991 20.7362 8.17317 21.2388C9.38642 21.7413 10.6868 22 12 22C13.3132 22 14.6136 21.7413 15.8268 21.2388C17.0401 20.7362 18.1425 19.9997 19.0711 19.0711C19.9997 18.1425 20.7362 17.0401 21.2388 15.8268C21.7413 14.6136 22 13.3132 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2C10.6868 2 9.38642 2.25866 8.17317 2.7612C6.95991 3.26375 5.85752 4.00035 4.92893 4.92893C4.00035 5.85752 3.26375 6.95991 2.7612 8.17317C2.25866 9.38642 2 10.6868 2 12Z"
                />
                <path d="M12 8V12" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path
                    d="M12 16H12.01"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </div>
    }
    @case (toastType.Fatal) {
        <div class="ui-toast-icon">
            <svg width="42" height="42" viewBox="0 0 42 42" class="stack">
                <circle
                    cx="21"
                    cy="21"
                    r="18.5"
                    stroke="#450A0A"
                    fill="transparent"
                    stroke-width="5"
                    stroke-miterlimit="4.62023"
                    stroke-dasharray="1 2"
                />
            </svg>
            <svg width="24" height="24" viewBox="0 0 24 24" class="stack">
                <path
                    d="M2 12C2 13.3132 2.25866 14.6136 2.7612 15.8268C3.26375 17.0401 4.00035 18.1425 4.92893 19.0711C5.85752 19.9997 6.95991 20.7362 8.17317 21.2388C9.38642 21.7413 10.6868 22 12 22C13.3132 22 14.6136 21.7413 15.8268 21.2388C17.0401 20.7362 18.1425 19.9997 19.0711 19.0711C19.9997 18.1425 20.7362 17.0401 21.2388 15.8268C21.7413 14.6136 22 13.3132 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2C10.6868 2 9.38642 2.25866 8.17317 2.7612C6.95991 3.26375 5.85752 4.00035 4.92893 4.92893C4.00035 5.85752 3.26375 6.95991 2.7612 8.17317C2.25866 9.38642 2 10.6868 2 12Z"
                    fill="#7F1D1D"
                    stroke="#7F1D1D"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M12 8V12M12 16H12.01"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </div>
    }
    @case (toastType.Warn) {
        <div class="ui-toast-icon">
            <svg viewBox="0 0 200 200" class="clock">
                <circle
                    cx="100"
                    cy="100"
                    r="50"
                    fill="none"
                    [@timerChange]="{ value: animationState, params: { duration: animationDuration } }"
                />
            </svg>
            <svg width="24" height="24" viewBox="0 0 24 24">
                <path
                    d="M9.10291 2.75H9.103H14.897H14.8971C15.1927 2.74996 15.4854 2.80817 15.7584 2.92128C16.0315 3.0344 16.2796 3.20021 16.4886 3.40925L16.4887 3.40933L20.5897 7.51033L20.59 7.51066C20.7993 7.71966 20.9653 7.96787 21.0785 8.24108C21.1918 8.51429 21.25 8.80715 21.25 9.10291V9.103V14.897V14.8971C21.25 15.1927 21.1918 15.4854 21.0787 15.7584C20.9656 16.0315 20.7998 16.2796 20.5908 16.4886L20.5907 16.4887L16.4897 20.5897L16.4896 20.5898C16.2806 20.7988 16.0325 20.9646 15.7594 21.0777C15.4864 21.1908 15.1937 21.249 14.8981 21.249H14.898H9.103H9.10291C8.80734 21.249 8.51465 21.1908 8.24157 21.0777C7.96849 20.9646 7.72038 20.7988 7.51141 20.5898L7.51127 20.5896L3.41027 16.4896L3.41 16.4893C3.20074 16.2803 3.03474 16.0321 2.92149 15.7589C2.80824 15.4857 2.74996 15.1928 2.75 14.8971V14.897V9.103V9.10291C2.74996 8.80733 2.80817 8.51465 2.92128 8.24157C3.0344 7.96849 3.20021 7.72038 3.40925 7.51141L3.40933 7.51133L7.51033 3.41033L7.51066 3.41C7.71966 3.20074 7.96787 3.03474 8.24108 2.92149C8.51429 2.80824 8.80715 2.74996 9.10291 2.75Z"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path d="M12 8V12" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path
                    d="M12 16H12.01"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </div>
    }
    @case (toastType.IncomingCall) {
        <div class="ui-toast-icon" [pTooltip]="message.detail" [showDelay]="1500" tooltipPosition="left">
            <svg width="24" height="24" viewBox="0 0 24 24">
                <path
                    d="M3 7.15385C3 14.8006 9.19938 21 16.8462 21H18.9231C19.4739 21 20.0022 20.7812 20.3917 20.3917C20.7812 20.0022 21 19.4739 21 18.9231V17.6566C21 17.1803 20.676 16.7649 20.2135 16.6495L16.1308 15.6286C15.7246 15.5271 15.2982 15.6794 15.048 16.0135L14.1526 17.2071C13.8923 17.5542 13.4428 17.7074 13.0357 17.5578C11.5245 17.0023 10.1521 16.1249 9.01364 14.9864C7.87515 13.8479 6.99773 12.4755 6.44215 10.9643C6.29262 10.5572 6.44585 10.1077 6.79292 9.84738L7.98646 8.952C8.32154 8.70185 8.47292 8.27446 8.37138 7.86923L7.35046 3.78646C7.29428 3.56187 7.16467 3.3625 6.9822 3.22C6.79974 3.07751 6.57489 3.00008 6.34338 3H5.07692C4.52609 3 3.99782 3.21882 3.60832 3.60832C3.21882 3.99782 3 4.52609 3 5.07692V7.15385Z"
                    fill="#026AA2"
                    stroke="#026AA2"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M13.05 7C14.0268 7.19057 14.9244 7.66826 15.6281 8.37194C16.3318 9.07561 16.8095 9.97326 17 10.95M13.05 3C15.0793 3.22544 16.9716 4.13417 18.4163 5.57701C19.8609 7.01984 20.7721 8.91101 21 10.94"
                    stroke="#026AA2"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </div>
    }
    @case (toastType.Info) {
        <div class="ui-toast-icon">
            <svg width="24" height="24" viewBox="0 0 24 24">
                <path
                    d="M3.45411 15.5398C2.98926 14.4176 2.75 13.2147 2.75 12C2.75 10.7853 2.98926 9.58244 3.45411 8.46018C3.91897 7.33792 4.60032 6.3182 5.45926 5.45926C6.3182 4.60032 7.33792 3.91897 8.46018 3.45411C9.58244 2.98926 10.7853 2.75 12 2.75C13.2147 2.75 14.4176 2.98926 15.5398 3.45411C16.6621 3.91897 17.6818 4.60032 18.5407 5.45926C19.3997 6.3182 20.081 7.33792 20.5459 8.46018C21.0107 9.58244 21.25 10.7853 21.25 12C21.25 13.2147 21.0107 14.4176 20.5459 15.5398C20.081 16.6621 19.3997 17.6818 18.5407 18.5407C17.6818 19.3997 16.6621 20.081 15.5398 20.5459C14.4176 21.0107 13.2147 21.25 12 21.25C10.7853 21.25 9.58244 21.0107 8.46018 20.5459C7.33792 20.081 6.3182 19.3997 5.45926 18.5407C4.60032 17.6818 3.91897 16.6621 3.45411 15.5398Z"
                    fill="#2173F3"
                    stroke="#2173F3"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M12 8H12.01"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M11 12H12V16H13"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </div>
    }
    @case (toastType.Empty) {
        <div class="ui-toast-icon">
            <svg width="24" height="24" viewBox="0 0 24 24">
                <path
                    d="M3.3476 15.5497C2.90043 14.4252 2.66992 13.2189 2.66992 12C2.66992 10.7811 2.90043 9.57484 3.3476 8.45029C3.79475 7.32579 4.4494 6.30597 5.27274 5.44833C6.09601 4.59075 7.07171 3.91219 8.14329 3.44984C9.21479 2.98752 10.3621 2.75 11.5199 2.75C12.6778 2.75 13.8251 2.98752 14.8966 3.44984C15.9681 3.91219 16.9438 4.59075 17.7671 5.44833C18.5904 6.30597 19.2451 7.32579 19.6922 8.45029C20.1394 9.57484 20.3699 10.7811 20.3699 12C20.3699 13.2189 20.1394 14.4252 19.6922 15.5497C19.2451 16.6742 18.5904 17.694 17.7671 18.5517C16.9438 19.4092 15.9681 20.0878 14.8966 20.5502C13.8251 21.0125 12.6778 21.25 11.5199 21.25C10.3621 21.25 9.21479 21.0125 8.14329 20.5502C7.07171 20.0878 6.09601 19.4092 5.27274 18.5517C4.4494 17.694 3.79475 16.6742 3.3476 15.5497Z"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M11.5201 16V16.01M11.5201 13C11.9518 13.0014 12.3714 12.8511 12.7111 12.5734C13.0508 12.2958 13.2907 11.907 13.3921 11.4698C13.4935 11.0327 13.4506 10.5726 13.2701 10.1641C13.0896 9.75549 12.7822 9.42219 12.3975 9.21801C12.0156 9.01422 11.5787 8.95103 11.158 9.03873C10.7372 9.12642 10.3573 9.35984 10.0801 9.70101"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    shape-rendering="geometricPrecision"
                />
            </svg>
        </div>
    }
}
