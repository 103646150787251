import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';
import { Tooltip } from 'primeng/tooltip';

@Component({
  selector: 'ui-pill',
  templateUrl: './pill.component.html',
  styleUrls: ['./pill.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, Tooltip],
})
export class PillComponent {
  @Input() emphasize = false;
  @Input() left!: string | number;
  @Input() leftTooltip!: string;
  @Input() leftBgColorClass!: string;
  @Input() leftTextColorClass!: string;
  @Input() right!: string | number;
  @Input() rightTooltip!: string;
  @Input() rightBgColorClass!: string;
  @Input() rightTextColorClass!: string;
  @Input() displayTooltip = false;

  get leftColors() {
    let bg = this.leftBgColorClass ?? 'bg-gray-200';
    let text = this.leftTextColorClass ?? 'text-neutral-700';

    if (this.emphasize) {
      bg = this.leftBgColorClass ?? 'bg-amber-300';
      text = this.leftTextColorClass ?? 'text-neutral-800';
    }

    return `${bg} ${text}`;
  }

  get rightColors() {
    let bg = this.rightBgColorClass ?? 'bg-gray-200';
    let text = this.rightTextColorClass ?? 'text-neutral-700';

    if (this.emphasize) {
      bg = this.rightBgColorClass ?? 'bg-gray-600';
      text = this.rightTextColorClass ?? 'text-white';
    }

    return `${bg} ${text}`;
  }
}
