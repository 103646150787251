<p-dialog
    [visible]="display()"
    (visibleChange)="onReject()"
    (onHide)="onHide()"
    (onShow)="onShow()"
    [modal]="true"
    [closable]="closable()"
    [focusOnShow]="focusOnShow()"
    [draggable]="false"
    [resizable]="false"
    [styleClass]="dialogStyleClass"
>
    @if (loading()) {
        <ui-loader />
    }

    <ng-template #header>
        {{ headerValue }}
        <ng-content select="header"></ng-content>
    </ng-template>

    <ng-template #content>
        @if (!loading()) {
            <div class="grid w-full grid-cols-[min(48px)_auto] gap-3">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="48"
                    width="48"
                    viewBox="0 0 24 24"
                    class="z-0 scale-400"
                    [innerHTML]="decorationIcon"
                ></svg>
                <div class="z-5 my-2 ml-28">
                    <ng-content></ng-content>
                </div>
            </div>
        }
    </ng-template>

    <ng-template #footer>
        <ui-button styleClass="p-button-secondary p-button-text" (onClick)="onReject()" [label]="rejectButtonLabel()" />
        <ui-button
            [styleClass]="acceptButtonStyleClass"
            [disabled]="disabled()"
            [loading]="storing()"
            (onClick)="onAccept()"
            [label]="acceptButtonLabel()"
        >
            <ng-template #icon>
                @if (acceptButtonIcon) {
                    <ui-svg [icon]="acceptButtonIcon" strokeColor="stroke-white" size="xs" />
                }
            </ng-template>
        </ui-button>
    </ng-template>
</p-dialog>
