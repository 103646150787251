<header
    #opTarget
    class="relative z-5 flex items-center justify-between bg-zinc-800 py-4 pl-6 pr-4 font-medium text-white"
>
    <img
        ngSrc="./assets/images/direct-logo--white.svg"
        priority="true"
        alt="Zpět na Dashboard"
        class="cursor-pointer"
        (click)="handleClickOnLogo()"
        height="36"
        width="100"
    />
    @if (isUserLogged) {
        <nav
            role="navigation"
            [ngClass]="{ 'justify-between': !disableHamburgerMenu, 'justify-end': disableHamburgerMenu }"
            class="flex w-full"
        >
            @if (!disableHamburgerMenu) {
                <div class="flex items-center pl-6">
                    <div class="toggle-menu" data-pf="toggle-menu" (click)="op.toggle($event)">
                        <label for="toggle-menu">
                            <input id="toggle-menu" type="checkbox" #menuToggle />
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    class="dot x"
                                    d="M3.76256 3.76256C4.09075 3.43438 4.53587 3.25 5 3.25C5.46413 3.25 5.90925 3.43438 6.23744 3.76256C6.56562 4.09075 6.75 4.53587 6.75 5C6.75 5.46413 6.56562 5.90925 6.23744 6.23744C5.90925 6.56562 5.46413 6.75 5 6.75C4.53587 6.75 4.09075 6.56562 3.76256 6.23744C3.43438 5.90925 3.25 5.46413 3.25 5C3.25 4.53587 3.43438 4.09075 3.76256 3.76256Z"
                                />
                                <path
                                    class="dot"
                                    d="M10.7626 3.76256C11.0908 3.43438 11.5359 3.25 12 3.25C12.4641 3.25 12.9092 3.43438 13.2374 3.76256C13.5656 4.09075 13.75 4.53587 13.75 5C13.75 5.46413 13.5656 5.90925 13.2374 6.23744C12.9092 6.56562 12.4641 6.75 12 6.75C11.5359 6.75 11.0908 6.56562 10.7626 6.23744C10.4344 5.90925 10.25 5.46413 10.25 5C10.25 4.53587 10.4344 4.09075 10.7626 3.76256Z"
                                />
                                <path
                                    class="dot x"
                                    d="M17.7626 3.76256C18.0908 3.43438 18.5359 3.25 19 3.25C19.4641 3.25 19.9092 3.43438 20.2374 3.76256C20.5656 4.09075 20.75 4.53587 20.75 5C20.75 5.46413 20.5656 5.90925 20.2374 6.23744C19.9092 6.56562 19.4641 6.75 19 6.75C18.5359 6.75 18.0908 6.56562 17.7626 6.23744C17.4344 5.90925 17.25 5.46413 17.25 5C17.25 4.53587 17.4344 4.09075 17.7626 3.76256Z"
                                />
                                <path
                                    class="dot"
                                    d="M3.76256 10.7626C4.09075 10.4344 4.53587 10.25 5 10.25C5.46413 10.25 5.90925 10.4344 6.23744 10.7626C6.56562 11.0908 6.75 11.5359 6.75 12C6.75 12.4641 6.56562 12.9092 6.23744 13.2374C5.90925 13.5656 5.46413 13.75 5 13.75C4.53587 13.75 4.09075 13.5656 3.76256 13.2374C3.43438 12.9092 3.25 12.4641 3.25 12C3.25 11.5359 3.43438 11.0908 3.76256 10.7626Z"
                                />
                                <path
                                    class="dot x"
                                    d="M10.7626 10.7626C11.0908 10.4344 11.5359 10.25 12 10.25C12.4641 10.25 12.9092 10.4344 13.2374 10.7626C13.5656 11.0908 13.75 11.5359 13.75 12C13.75 12.4641 13.5656 12.9092 13.2374 13.2374C12.9092 13.5656 12.4641 13.75 12 13.75C11.5359 13.75 11.0908 13.5656 10.7626 13.2374C10.4344 12.9092 10.25 12.4641 10.25 12C10.25 11.5359 10.4344 11.0908 10.7626 10.7626Z"
                                />
                                <path
                                    class="dot"
                                    d="M17.7626 10.7626C18.0908 10.4344 18.5359 10.25 19 10.25C19.4641 10.25 19.9092 10.4344 20.2374 10.7626C20.5656 11.0908 20.75 11.5359 20.75 12C20.75 12.4641 20.5656 12.9092 20.2374 13.2374C19.9092 13.5656 19.4641 13.75 19 13.75C18.5359 13.75 18.0908 13.5656 17.7626 13.2374C17.4344 12.9092 17.25 12.4641 17.25 12C17.25 11.5359 17.4344 11.0908 17.7626 10.7626Z"
                                />
                                <path
                                    class="dot x"
                                    d="M3.76256 17.7626C4.09075 17.4344 4.53587 17.25 5 17.25C5.46413 17.25 5.90925 17.4344 6.23744 17.7626C6.56562 18.0908 6.75 18.5359 6.75 19C6.75 19.4641 6.56562 19.9092 6.23744 20.2374C5.90925 20.5656 5.46413 20.75 5 20.75C4.53587 20.75 4.09075 20.5656 3.76256 20.2374C3.43438 19.9092 3.25 19.4641 3.25 19C3.25 18.5359 3.43438 18.0908 3.76256 17.7626Z"
                                />
                                <path
                                    class="dot"
                                    d="M10.7626 17.7626C11.0908 17.4344 11.5359 17.25 12 17.25C12.4641 17.25 12.9092 17.4344 13.2374 17.7626C13.5656 18.0908 13.75 18.5359 13.75 19C13.75 19.4641 13.5656 19.9092 13.2374 20.2374C12.9092 20.5656 12.4641 20.75 12 20.75C11.5359 20.75 11.0908 20.5656 10.7626 20.2374C10.4344 19.9092 10.25 19.4641 10.25 19C10.25 18.5359 10.4344 18.0908 10.7626 17.7626Z"
                                />
                                <path
                                    class="dot x"
                                    d="M17.7626 17.7626C18.0908 17.4344 18.5359 17.25 19 17.25C19.4641 17.25 19.9092 17.4344 20.2374 17.7626C20.5656 18.0908 20.75 18.5359 20.75 19C20.75 19.4641 20.5656 19.9092 20.2374 20.2374C19.9092 20.5656 19.4641 20.75 19 20.75C18.5359 20.75 18.0908 20.5656 17.7626 20.2374C17.4344 19.9092 17.25 19.4641 17.25 19C17.25 18.5359 17.4344 18.0908 17.7626 17.7626Z"
                                />
                            </svg>
                            <span>Menu</span>
                        </label>
                    </div>
                    <p-popover
                        #op
                        (onHide)="handleMenuDisplay()"
                        styleClass="nc-header-menu w-full rounded-b-2xl before:hidden after:hidden"
                    >
                        <ng-template pTemplate="content">
                            <ng-content select="[menu-items]"> </ng-content>
                        </ng-template>
                    </p-popover>
                </div>
            }
            <div class="flex animate-fade-in" style="opacity: 0">
                <div class="flex items-center gap-4">
                    <ng-content select="[button-menu]"></ng-content>
                </div>
                @if (isUserLogged) {
                    <p-divider layout="vertical" />
                }
                @if (isUserLogged) {
                    <div class="relative flex items-center">
                        <ui-button
                            (onClick)="handleUserAccountDisplay($event)"
                            data-pf="header-user-account"
                            styleClass="user-account"
                        >
                            <div class="relative inline-flex items-center justify-center">
                                @if (avatar) {
                                    <img
                                        [uiSecuredImage]="avatar"
                                        [inline]="true"
                                        [imageWidth]="200"
                                        alt=""
                                        class="absolute"
                                    />
                                }
                                <ui-svg icon="Avatar" size="lg" strokeColor="stroke-white" />
                            </div>
                            <div class="mr-4 flex flex-col items-start leading-4">
                                <span class="font-bold">{{ realName }}</span>
                                @if (email) {
                                    <span class="text-xxs text-zinc-400">{{ email }}</span>
                                }
                            </div>
                            <ui-svg icon="Chevron Down" size="xs" strokeColor="stroke-white" />
                        </ui-button>
                        <p-menu
                            #userOptions
                            [styleClass]="(hasScrollbar() ? '-ml-4 ' : '') + 'mt-4'"
                            [model]="userOptionItems"
                            [popup]="true"
                        >
                            <ng-template pTemplate="item" let-item>
                                <ui-svg [icon]="item.icon" strokeColor="stroke-white" />
                                <span class="text-white">{{ item.label }}</span>
                                <span
                                    class="ml-auto mr-2 inline-flex items-center rounded-md bg-white/5 px-3 py-1 text-xs text-zinc-400"
                                    >Alt + Shift + L</span
                                >
                            </ng-template>
                        </p-menu>
                    </div>
                }
            </div>
        </nav>
    }
</header>
