import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { Ace, config, edit } from 'ace-builds';

@Component({
  selector: 'ui-cv--code-editor-legacy',
  templateUrl: './code-editor.component.html',
  styleUrls: ['./code-editor.scss'],
})
export class CodeEditorComponent implements OnInit, AfterViewInit {
  @Input() readonly textForEdit!: string;
  @Input() readonly isCorrect!: boolean;
  @Input() set refObject(name: string) {
    if (this.aceEditor) {
      this.aceEditor.session.setValue(this.textForEdit || '');
    }
  }

  @Output() private readonly textOutput = new EventEmitter<string>();
  @Output() private readonly editorBlur = new EventEmitter<string>();

  aceEditor!: Ace.Editor;
  aceEditorExtended = false;

  @ViewChild('aceEditor') private editor!: ElementRef<HTMLElement>;

  ngOnInit(): void {
    this.aceEditor && this.aceEditor.session.setValue(this.textForEdit || '');
    this.aceEditorExtended = false;
  }

  ngAfterViewInit(): void {
    this.initAceEditor();
  }

  private initAceEditor(): void {
    config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');
    this.aceEditor = edit(this.editor.nativeElement);
    this.aceEditor.setOptions({ fontSize: '14px' });
    this.aceEditor.setTheme('ace/theme/clouds');
    this.aceEditor.setShowPrintMargin(false);
    this.aceEditor.on('change', () => {
      this.textOutput.next(this.aceEditor.getValue());
    });
    this.aceEditor.on('blur', () => {
      this.editorBlur.next('blur');
    });

    this.aceEditor.session.setValue(this.textForEdit || '');
    this.aceEditor.session.setMode('ace/mode/pgsql');
    this.aceEditor.session.setTabSize(2);
  }
}
