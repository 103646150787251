import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CommonMessages, DateTimeFormat, RetryAndContactSupportMessage, ValidationMessages } from '@domain';
import { FileUpload, FileUploadModule } from 'primeng/fileupload';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { FormControlService, PipesModule, getReadableFileSizeString, isDateInPastValidator } from '@utils';
import { KeyValuePipe } from '@angular/common';
import { ReportIssueCategories, ReportIssueForm } from './model/report-issue-form.model';

import { ButtonComponent } from '../../primeng/button/button.component';
import { DialogModule } from '../../primeng/dialog/dialog.module';
import { DividerModule } from 'primeng/divider';
import { DropdownComponent } from '../../primeng/dropdown/dropdown.component';
import { InputTextModule } from 'primeng/inputtext';
import { LabelModule } from '../../primeng/label/label.module';
import { MessageModule } from '../../primeng/message/message.module';
import { ReportIssueRequest } from './model/report-issue-request.interface';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';
import { SwitchModule } from '../../primeng/switch/switch.module';
import { ValidationErrorComponent } from '../validation-message/validation-message.component';
import dayjs from 'dayjs';
import { reportIssueOpt } from './report-issue.opt';
import { Textarea } from 'primeng/textarea';
import { CalendarComponent } from '../../primeng/calendar/calendar.component';

@Component({
  selector: 'ui-report-issue',
  templateUrl: './report-issue.component.html',
  styleUrls: ['./report-issue.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    DialogModule,
    MessageModule,
    ReactiveFormsModule,
    ButtonComponent,
    FileUploadModule,
    LabelModule,
    SvgIconComponent,
    ValidationErrorComponent,
    DividerModule,
    CalendarComponent,
    InputTextModule,
    SwitchModule,
    PipesModule,
    DropdownComponent,
    KeyValuePipe,
    Textarea,
  ],
  providers: [FormControlService],
})
export class ReportIssueComponent implements OnInit {
  @Input() readonly loading: boolean = false;
  @Input() readonly error: boolean = false;
  @Output() readonly onClose = new EventEmitter<void>();
  @Output() readonly onReportIssue = new EventEmitter<ReportIssueRequest>();

  @ViewChild('docsUpload') docsUpload!: FileUpload;

  readonly commonMessages = CommonMessages;
  readonly reportIssueOpt = reportIssueOpt;
  readonly validationMessages = ValidationMessages;
  readonly maxDate = new Date();
  readonly errorMessage = `Nepodařilo se odeslat hlášení.\n\n ${RetryAndContactSupportMessage}`;

  form!: FormGroup<ReportIssueForm>;
  totalSize = 0;

  reportIssueCategories = ReportIssueCategories;

  constructor(
    readonly formService: FormControlService,
    private readonly fb: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group<ReportIssueForm>({
      description: new FormControl(null, Validators.required),
      category: new FormControl('GENERAL', Validators.required),
      date: new FormControl(new Date(), isDateInPastValidator('minute')),
      fishtag: new FormControl(null),
      vpn: new FormControl(false, { nonNullable: true }),
      otherColleagues: new FormControl(false, { nonNullable: true }),
      url: new FormControl(window.location.href, Validators.required),
    });

    // set window.location.href as default value for url, after category change
    this.form.controls.category.valueChanges.subscribe(() => {
      this.form.controls.url.setValue(window.location.href);
    });
  }

  onSelectFiles(): void {
    this.totalSize = 0;
    this.docsUpload.files = this.docsUpload.files.filter((file) => {
      if (+file.size === 0) {
        this.docsUpload?.msgs?.push({
          severity: 'error',
          summary: this.reportIssueOpt.docsUpload.invalidMinFileSizeMessage.replace('{0}', file.name),
          detail: undefined,
        });
        return null; // TODO
      }
      this.totalSize += file.size;
      return file;
    });
  }

  onRemove(uploader: FileUpload, file: File, event: Event): void {
    const index = uploader.files.indexOf(file);
    uploader.remove(event, index);
    this.totalSize -= file.size;
  }

  getHumanFileSize(bytes: number): string {
    return getReadableFileSizeString(bytes);
  }

  closeModal(): void {
    this.onClose.emit();
  }

  reportIssue(): void {
    this.onReportIssue.emit({
      ...this.form.getRawValue(),
      date: this.form.controls.date.value
        ? dayjs(this.form.controls.date.value).format(DateTimeFormat.DateTimeISODayJS)
        : null,
      documents: this.docsUpload?.files || [],
      url: this.form.controls.url.value,
    });
  }
}
