@if (isVisible) {
    <div
        #toast
        class="ui-toast message-{{ message.severity }}"
        [@messageState]="{ value: 'visible' }"
        (@messageState.start)="animationTimerStart()"
        (mouseenter)="onMouseEnter()"
        (mouseleave)="onMouseLeave()"
        [@positionChange]="position"
    >
        <div class="ui-toast-wrapper flex gap-x-4 py-2 pl-4 pr-2">
            <ui-toast-icon
                [message]="message"
                [animationDuration]="animationDuration"
                [animationState]="animationState"
            />

            <ui-toast-content
                [message]="message"
                [textCopied]="textCopied"
                (onCopyText)="copyText()"
                (onCopyPhone)="copyPhone()"
                (onCopyPhoneAndRedirect)="copyPhoneAndRedirect()"
            />

            <ui-toast-actions
                [message]="message"
                [position]="position"
                (onTogglePosition)="togglePosition()"
                (onClose)="close()"
            />
        </div>

        <ui-toast-buttons [message]="message" [toastButtons]="toastButtons$ | async" />
    </div>
}
