<p-accordion class="state-{{ error ? 'error' : colorMode }}" [value]="accordionId()">
    <p-accordion-panel #accordionPanel [disabled]="disabled" [value]="panelValue()">
        <p-accordion-header>
            <ng-template #toggleicon let-active="active">
                <ui-svg
                    [icon]="active ? 'Chevron Down' : 'Chevron Right'"
                    [strokeColor]="colorMode === 'white' ? 'stroke-black' : 'stroke-white'"
                    class="inline-flex"
                />
            </ng-template>

            <div class="flex h-[24px] w-full gap-2">
                <div class="header-icon">
                    <ng-content select="header-icon"></ng-content>
                </div>
                <div class="header-left">
                    <span class="self-center text-md leading-5">{{ header }}</span>
                    <ng-content select="header-left"></ng-content>
                    @if (error) {
                        <ui-message severity="error" [title]="commonMessages.CannotLoadData" />
                    }
                    @if (!error && displayMessage) {
                        <ui-message [severity]="messageSeverity" [title]="messageTitle" />
                    }
                </div>
                <div class="header-actions">
                    @if (loading()) {
                        <ui-loader variant="ring" />
                    }
                    <ng-content select="header-right"></ng-content>
                </div>
            </div>
        </p-accordion-header>
        <p-accordion-content>
            <div class="content-wrapper">
                <ui-accordion-content [colorMode]="colorMode" [styleClass]="contentStyleClass">
                    @if (error) {
                        <div class="flex w-full justify-center">
                            <ui-message severity="error" [title]="messageTitle ?? commonMessages.CannotLoadData" />
                        </div>
                    }
                    <ng-content></ng-content>
                </ui-accordion-content>
            </div>
        </p-accordion-content>
    </p-accordion-panel>
</p-accordion>
@if (mode === displayMode.Stacked) {
    <div class="stack" [ngClass]="[openStacked() ? 'h-0' : 'h-1.5', error ? 'bg-rose-500' : 'bg-primary-500']"></div>
}
