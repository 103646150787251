import { AccordionPanel } from 'primeng/accordion';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  Signal,
  computed,
  effect,
  inject,
  input,
  output,
  signal,
  viewChild,
} from '@angular/core';

import { CommonMessages } from '@domain';
import { DisplayMode } from './accordion.enum';
import { SeverityType } from '../message/message.model';
import { UserProfileService } from '@framework';

@Component({
  selector: 'ui-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccordionComponent implements OnInit {
  selected = input(false);
  @Input() disabled = false;
  @Input() header!: string;
  @Input() headerActions = false;
  loading = input(false);
  @Input() mode = DisplayMode.Flat;
  @Input() error!: boolean;
  @Input() displayMessage!: boolean;
  @Input() messageSeverity!: SeverityType;
  @Input() messageTitle!: string;
  @Input() cache = false;
  @Input() colorMode: 'blue' | 'gray' | 'gray-gradient' | 'white' = 'blue';
  @Input() contentStyleClass!: string;

  readonly accordionId = input.required<string>();

  readonly onOpen = output();
  readonly onClose = output();

  accordionPanel = viewChild.required<AccordionPanel>('accordionPanel');

  private readonly profile = inject(UserProfileService);

  readonly displayMode = DisplayMode;
  readonly commonMessages = CommonMessages;

  openStacked = computed(() => this.selected() && this.accordionPanel().active());
  panelValue = computed(() => (this.isOpen() && !this.loading() ? this.accordionId() : ''));

  isOpen: Signal<boolean> = signal<boolean>(false);

  constructor() {
    effect(() => {
      if (this.accordionPanel().active()) {
        this.onOpen.emit();
      } else {
        this.onClose.emit(); // emits initial value
      }
      this.profile.setPreference(this.accordionId(), this.accordionPanel().active());
    });
  }

  ngOnInit(): void {
    this.isOpen = computed(() => {
      const opened = this.profile.getPreference(this.accordionId());
      if (opened === null || opened === undefined) {
        return this.selected();
      }
      return opened;
    });
  }
}
