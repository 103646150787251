<p-radioButton
    #radioButton
    [binary]="binary()"
    [disabled]="isDisabled()"
    [formControlName]="formControlName()"
    [inputId]="inputId()"
    [name]="name()"
    [pTooltip]="tooltip()"
    [styleClass]="styleClass()"
    [tooltipPosition]="tooltipPosition()"
    [value]="value()"
    (onBlur)="onRadioButtonBlur()"
    (onClick)="onRadioButtonClick($event)"
    (onFocus)="onRadioButtonFocus($event)"
/>
<label [for]="inputId()" [pTooltip]="tooltip()" [tooltipPosition]="tooltipPosition()">{{ label() }}</label>
