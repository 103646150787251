<p-dialog
    [appendTo]="appendTo()"
    [visible]="display() || visible()"
    [modal]="modal()"
    [blockScroll]="blockScroll()"
    [closeOnEscape]="closeOnEsc()"
    [closable]="closable()"
    [draggable]="draggable()"
    [resizable]="resizable()"
    [maximizable]="maximizable()"
    [position]="position()"
    [focusOnShow]="focusOnShow()"
    (visibleChange)="onReject()"
    [styleClass]="styleClass()"
    [contentStyleClass]="contentClass()"
    [maskStyleClass]="maskClass()"
    (onShow)="onAppear()"
    (onHide)="onDisappear()"
>
    <ng-template #header>
        {{ headerValue }}
        <ng-content select="header"></ng-content>
    </ng-template>

    <p-blockUI [blocked]="loading() || storing() || blocked()" [target]="blockUiContainer" />
    <ui-block-ui-container #blockUiContainer>
        <div
            class="p-dialog-content-container"
            [ngClass]="{
                ready: !loading() && !storing(),
                loading: loading(),
                saving: storing(),
            }"
        >
            @if (loading() || storing()) {
                <ui-loader />
            }
            <ng-content></ng-content>
        </div>
    </ui-block-ui-container>

    <ng-template #footer>
        <div class="flex grow items-center justify-start gap-2">
            <ng-content select="footer-left"></ng-content>
        </div>
        <div class="flex grow items-center justify-end gap-2">
            @if (showRejectButton()) {
                <ui-button
                    styleClass="p-button-secondary p-button-text"
                    (onClick)="onReject()"
                    [label]="rejectButtonLabel()"
                />
            }
            @if (showAcceptButton()) {
                <ui-button
                    [disabled]="disabled()"
                    [loading]="storing()"
                    [pTooltip]="acceptButtonTooltip()"
                    tooltipPosition="top"
                    (onClick)="onAccept()"
                    [label]="acceptButtonLabel()"
                />
            }
            <ng-content select="footer-right"></ng-content>
        </div>
    </ng-template>
</p-dialog>
