export enum StatusMode {
  NORMAL = 'normal',
  FILL = 'fill',
}

export enum StatusColor {
  AMBER = 'amber',
  GREEN = 'green',
  BLUE = 'blue',
  RED = 'red',
  ORANGE = 'orange',
  SLATE = 'slate',
  ZINC = 'zinc',
  NEUTRAL = 'neutral',
}

export const stateColors = {
  CREATED: StatusColor.BLUE,
  PAYMENT_INITIATED: StatusColor.BLUE,
  INITIAL: StatusColor.BLUE,
  COMPLETION_DONE: StatusColor.BLUE,
  PENDING: StatusColor.AMBER,
  PAYMENT_PENDING: StatusColor.AMBER,
  IN_PROGRESS: StatusColor.AMBER,
  OPENED: StatusColor.AMBER,
  ASSIGNED: StatusColor.AMBER,
  COMPLETED: StatusColor.GREEN,
  AUTO_COMPLETED: StatusColor.GREEN,
  RECEIVED: StatusColor.ORANGE,
  TERMINATED: StatusColor.RED,
  REJECTED: StatusColor.RED,
  INACTIVE: StatusColor.RED,
  CANCELLED: StatusColor.ZINC,
  CANCELED: StatusColor.ZINC,
  CLOSED: StatusColor.RED,
  SUSPENDED: StatusColor.SLATE,
  MISSED: StatusColor.SLATE,
  POSTPONED: StatusColor.SLATE,
  WAITING: StatusColor.GREEN,
  ALIVE: StatusColor.GREEN,
  ACTIVE: StatusColor.GREEN,
  AUTO_APPROVED: StatusColor.GREEN,
  REVOKED: StatusColor.RED,
  UNDERWRITING: StatusColor.AMBER,
  CALCULATED: StatusColor.ZINC,
  SUBMITTED: StatusColor.ORANGE,
  EXPIRED: StatusColor.RED,
  PART: StatusColor.ORANGE,
  FULL: StatusColor.GREEN,
  NONE: StatusColor.RED,
  BEFORE_DUE_DATE: StatusColor.ZINC,
};
