<p-message [severity]="severity" [styleClass]="styleClass">
    <div class="flex gap-2" [ngClass]="iconAlign === 'center' ? 'items-center' : 'items-start'">
        <ui-svg [icon]="iconName" mode="fill" [size]="size" fillColor="fill-inherit" strokeColor="stroke-transparent" />
        <span class="p-message-summary">
            <b>{{ title }}</b>
        </span>
        <span class="p-message-detail">
            <ng-content></ng-content>
        </span>
    </div>
</p-message>
