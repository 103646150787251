<p-button
    type="button"
    [icon]="icon"
    [badge]="badge"
    [label]="label"
    [disabled]="disabled"
    [loading]="loading"
    [style]="style"
    [styleClass]="styleClass"
    [outlined]="outlined"
    [text]="text"
    [rounded]="rounded"
    [size]="size"
    [type]="type"
    (onClick)="onClick.emit($event)"
    (onFocus)="onFocus.emit($event)"
    (onBlur)="onBlur.emit($event)"
>
    <ng-content></ng-content>
</p-button>
