import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  TemplateRef,
  contentChildren,
  inject,
  input,
  output,
  signal,
  viewChild,
} from '@angular/core';
import { Select, SelectChangeEvent, SelectFilterEvent } from 'primeng/select';
import { SvgIconComponent } from '../../non-primeng/svg-icon/svg-icon.component';
import { LoaderComponent } from '../../non-primeng/loader/loader.component';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { CommonMessages } from '@domain';
import { FilterMatchMode } from './dropdown.model';
import { NgTemplateOutlet } from '@angular/common';
import { PrimeTemplate } from 'primeng/api';
import { LabelModule } from '../label/label.module';

@Component({
  selector: 'ui-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrl: './dropdown.component.scss',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [Select, SvgIconComponent, LoaderComponent, NgTemplateOutlet, LabelModule],
})
export class DropdownComponent implements AfterViewInit, ControlValueAccessor {
  placeholder = input(CommonMessages.ChoosePlaceholder);
  appendTo = input('body');
  autoDisplayFirst = input(true);
  autofocus = input(false);
  autofocusFilter = input(false);
  emptyFilterMessage = input('');
  emptyMessage = input('');
  filter = input(false);
  filterBy = input<string>();
  filterFields = input<any[]>();
  filterMatchMode = input<FilterMatchMode>('contains');
  filterPlaceholder = input<string>();
  filterValue = input<string>();
  group = input(false);
  fluid = input(true);
  inputId = input<string>();
  lazy = input(false);
  label = input<string>();
  labelTrailing = input<string>();
  loading = input(false);
  name = input<string>();
  options = input<any[]>();
  optionDisabled = input<string>();
  optionLabel = input<string>();
  optionValue = input<string>();
  panelStyleClass = input<string>();
  readonly = input(false);
  resetFilterOnHide = input(false);
  scrollHeight = input<string>('200px');
  showClear = input(false);
  styleClass = input<string>();
  tooltip = input('');
  tooltipPosition = input<'top' | 'left' | 'right' | 'bottom'>('right');
  virtualScroll = input(false);
  virtualScrollItemSize = input<number>();

  onChange = output<SelectChangeEvent>();
  onClear = output<Event>();
  onClick = output<MouseEvent>();
  onFilter = output<SelectFilterEvent>();
  onFocus = output<Event>();

  select = viewChild.required<Select>('select');
  templates = contentChildren(PrimeTemplate);

  onChanged: (value: unknown) => void = () => {};
  onTouched: VoidFunction = () => {};
  isDisabled = signal(false);
  itemTemplate: TemplateRef<any> | undefined;
  selectedItemTemplate: TemplateRef<any> | undefined;
  groupTemplate: TemplateRef<any> | undefined;

  private _control = inject(NgControl, { optional: true });

  constructor() {
    if (this._control) {
      this._control.valueAccessor = this;
    }
  }

  ngAfterViewInit(): void {
    this.templates().forEach((item) => {
      switch (item.getType()) {
        case 'item':
          this.itemTemplate = item.template;
          break;
        case 'selectedItem':
          this.selectedItemTemplate = item.template;
          break;
        case 'group':
          this.groupTemplate = item.template;
          break;
      }
    });
  }

  registerOnChange(fn: (value: unknown) => void): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: VoidFunction): void {
    this.onTouched = fn;
  }

  writeValue(value: unknown): void {
    this.select().writeValue(value);
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled.set(isDisabled);
  }

  onSelectBlur(): void {
    if (this.autofocusFilter() && this._control?.pristine) {
      return;
    }
    this.onTouched();
  }

  onSelectChange(event: SelectChangeEvent): void {
    this.onChanged(event.value);
    this.onChange.emit(event);
  }

  onSelectClear(event: Event): void {
    this.onClear.emit(event);
  }

  onSelectFilter(event: SelectFilterEvent): void {
    this.onFilter.emit(event);
  }

  onSelectFocus(event: Event): void {
    this.onFocus.emit(event);
  }

  onSelectHide(): void {
    this.onTouched();
  }

  onLabelClick(select: Select) {
    if (!this.readonly()) {
      select.focus();
      select.show();
    }
  }

  show(): void {
    this.select().show();
  }

  hide(): void {
    this.select().hide();
  }
}
