import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, OnInit } from '@angular/core';
import { ToastMessage, ToastService, ToastType } from '@abstractions';

import { animateChild, query, transition, trigger } from '@angular/animations';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ToastComponent } from './toast.component';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';
import { Tooltip } from 'primeng/tooltip';

@Component({
  selector: 'ui-toast-container',
  templateUrl: './toast.container.html',
  styleUrls: ['./toast.container.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [trigger('toastAnimation', [transition(':enter, :leave', [query('@*', animateChild())])])],
  standalone: true,
  imports: [ToastComponent, SvgIconComponent, Tooltip],
})
export class ToastContainer implements OnInit {
  toasts!: ToastMessage[];
  toastType = ToastType;

  constructor(
    private readonly service: ToastService,
    private readonly cd: ChangeDetectorRef,
    private destroyRef: DestroyRef,
  ) {}

  ngOnInit(): void {
    this.watchToastMessages();
  }

  add(toast: ToastMessage): void {
    this.service.add(toast);
  }

  close(id: string): void {
    this.service.close(id);
  }

  closeAll(filter: ToastType | null = null): void {
    if (filter) {
      this.toasts
        .filter((toast: ToastMessage) => toast.severity === filter)
        .forEach((t: ToastMessage) => this.close(t?.id ?? ''));
    } else {
      this.toasts.forEach((toast: ToastMessage) => this.close(toast?.id ?? ''));
    }
  }

  private watchToastMessages(): void {
    this.service.toasts$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((toasts) => {
      this.toasts = toasts;
      this.cd.detectChanges();
    });
  }
}
