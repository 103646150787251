@if (toasts.length) {
    <div class="toasts-container">
        @if (toasts.length > 3) {
            <a
                (click)="closeAll(toastType.Fatal)"
                class="absolute -right-8 top-0 flex justify-center rounded-full bg-black/30 py-1 pl-8 pr-2 text-gray-600 opacity-0 hover:text-gray-100 hover:no-underline hover:opacity-100"
                ><ui-svg icon="X Mark Circle" strokeColor="white" size="xs" pTooltip="Skrýt všechna oznámení"
            /></a>
        }
        @let reversed = toasts.slice().reverse();
        @for (toast of reversed; track $index) {
            <ui-toast
                [message]="toast"
                style="--toast-translate-y: {{ $index * 10 }}px; --toast-scale: {{
                    1 - $index * 0.025
                }}; --toast-order: {{ toasts.length - $index }}"
                (onClose)="close($event)"
                @toastAnimation
            />
        }
    </div>
}
