import { BadgeModule } from 'primeng/badge';
import { LabelComponent } from './label.component';
import { NgModule } from '@angular/core';

@NgModule({
  imports: [BadgeModule],
  declarations: [LabelComponent],
  exports: [LabelComponent],
})
export class LabelModule {}
