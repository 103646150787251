<label class="mb-2" [htmlFor]="inputId()">
    <span class="inline-flex">
        @if (!!badge()) {
            <p-badge [value]="badge() || ''" [styleClass]="badgeStyleClass()" />
        }

        @if (label()) {
            <span class="self-center empty:hidden">{{ label() }}</span>
        }

        @if (!label()) {
            <span class="self-center empty:hidden"><ng-content></ng-content></span>
        }
    </span>

    @if (trailing()) {
        <em>{{ trailing() }}</em>
    }
</label>
