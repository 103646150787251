import { CommonModule } from '@angular/common';
import { MessageComponent } from './message.component';
import { NgModule } from '@angular/core';
import { SvgIconComponent } from '../../non-primeng/svg-icon/svg-icon.component';
import { Message } from 'primeng/message';
import { PrimeTemplate } from 'primeng/api';

@NgModule({
  declarations: [MessageComponent],
  imports: [CommonModule, SvgIconComponent, Message, PrimeTemplate],
  exports: [MessageComponent],
})
export class MessageModule {}
