import { Attribute, Directive, HostBinding } from '@angular/core';

import { NgControl } from '@angular/forms';

@Directive({
  selector: 'select[uiInputFieldLegacy]',
  host: {
    class: 'c-form-control',
  },
})
export class SelectFieldDirective {
  @HostBinding('id') id!: string;

  constructor(
    @Attribute('formControlName') public formControlName: string,
    public formControl: NgControl,
  ) {}
}
