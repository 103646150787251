<div class="ui-toast-buttons">
    @for (button of toastButtons; track $index) {
        @if (button.enabled && message.data?.code === button.type) {
            <ui-button
                [label]="button.label"
                (onClick)="button.action(message)"
                styleClass="p-button-text p-button-secondary"
            />
        }
        <p-divider></p-divider>
    }
</div>
