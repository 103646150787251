import { AccessControlModule } from '@framework';
import { AngleComponent } from './angle.component';
import { AutoresizeDirective } from './autoresize.directive';
import { BoxComponent } from './box.component';
import { ButtonComponent } from './button.component';
import { CodeEditorComponent } from './code-editor/code-editor.component';
import { CommonModule } from '@angular/common';
import { DcButtonDirective } from './button.directive';
import { DcSpinnerButtonDirective } from './spinner-button.directive';
import { DocumentLoaderComponent } from './document-loader.component';
import { ElementOutletComponent } from './element-outlet.component';
import { FormsModule } from '@angular/forms';
import { HeadingComponent } from './heading.component';
import { IconComponent } from './icon.component';
import { IframeDirective } from './iframe.directive';
import { LabeledValueComponent } from './labeled-value.component';
import { LazyImageDirective } from './lazy-image.directive';
import { MasterOutletDirective } from './master-outlet.directive';
import { NgModule } from '@angular/core';
import { OverlayDirective } from './overlay.directive';
import { PositionInViewportDirective } from './position-in-viewport.directive';
import { PrimaryButtonComponent } from './primary-button.component';
import { SkeletonComponent } from './skeleton.component';
import { SpinnerComponent } from './spinner.component';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { WindowScrollService } from '../../../services/window-scroll.service';

const components = [
  HeadingComponent,
  BoxComponent,
  SpinnerComponent,
  IconComponent,
  AngleComponent,
  SkeletonComponent,
  DcButtonDirective,
  ButtonComponent,
  PrimaryButtonComponent,
  AutoresizeDirective,
  LazyImageDirective,
  DcSpinnerButtonDirective,
  IframeDirective,
  ElementOutletComponent,
  MasterOutletDirective,
  LabeledValueComponent,
  OverlayDirective,
  PositionInViewportDirective,
  DocumentLoaderComponent,
  CodeEditorComponent,
];

@NgModule({
  declarations: components,
  imports: [CommonModule, AccessControlModule, ToggleButtonModule, FormsModule],
  exports: components,
  providers: [WindowScrollService],
})
export class BaseElementsModule {}
