import { AccordionComponent } from './accordion.component';
import { AccordionContentComponent } from './accordion-content/accordion-content.component';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from '../../non-primeng/loader/loader.component';
import { MessageModule } from '../message/message.module';
import { NgModule } from '@angular/core';
import { AccordionModule as PrimeAccordionModule } from 'primeng/accordion';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SvgIconComponent } from '../../non-primeng/svg-icon/svg-icon.component';

@NgModule({
  imports: [
    PrimeAccordionModule,
    CommonModule,
    ProgressSpinnerModule,
    LoaderComponent,
    MessageModule,
    SvgIconComponent,
  ],
  declarations: [AccordionComponent, AccordionContentComponent],
  exports: [AccordionComponent],
})
export class AccordionModule {}
