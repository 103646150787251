@if (label()) {
    <ui-label [label]="label()" [trailing]="labelTrailing()" />
}
<p-datePicker
    #calendar
    [appendTo]="appendTo()"
    [defaultDate]="preselectedDate()"
    [inputId]="inputId()"
    [name]="name()"
    [fluid]="fluid()"
    [selectionMode]="mode()"
    [placeholder]="placeholder()"
    [minDate]="minDate()"
    [maxDate]="maxDate()"
    [numberOfMonths]="months()"
    [readonlyInput]="readonly()"
    [showOtherMonths]="showOtherMonths()"
    [showTime]="displayTime"
    [showClear]="showClear()"
    [disabled]="isDisabled()"
    [showIcon]="showIcon()"
    [keepInvalid]="keepInvalid()"
    [selectOtherMonths]="selectOtherMonths()"
    [styleClass]="classes()"
    [panelStyle]="panelStyle()"
    [panelStyleClass]="panelClasses()"
    (onSelect)="onSelect(calendar)"
    (onClose)="onClose(calendar)"
    (onClear)="onClear(calendar)"
    (onInput)="onManualInput($event)"
    (onMonthChange)="onMonthYearChange()"
    (onYearChange)="onMonthYearChange()"
    (onBlur)="onBlur(calendar)"
>
    <ng-template #triggericon>
        <ui-svg icon="Calendar" strokeColor="stroke-gray-400" />
    </ng-template>

    @if (customPresets()) {
        <ng-template #footer>
            <ng-content select="presets"></ng-content>
        </ng-template>
    }

    @if (showPresets()) {
        <p-footer>
            @if (mode() === selectionMode.Single && isMinDateLesserThanToday()) {
                <ui-button
                    label="Minulý týden"
                    (onClick)="applyPreset(-1, 'week')"
                    styleClass="p-button-secondary p-button-text"
                />
                <ui-button
                    label="Minulý měsíc"
                    (onClick)="applyPreset(-1, 'month')"
                    styleClass="p-button-secondary p-button-text"
                />
                <ui-button
                    label="Minulý rok"
                    (onClick)="applyPreset(-1, 'year')"
                    styleClass="p-button-secondary p-button-text"
                />
            }
            @if (mode() === selectionMode.Single && isTodayLesserThanMaxDate()) {
                <ui-button
                    label="Dnes"
                    (onClick)="applyPreset(0, 'day')"
                    styleClass="p-button-secondary p-button-text"
                />
                <ui-button
                    label="Včera"
                    (onClick)="applyPreset(-1, 'day')"
                    styleClass="p-button-secondary p-button-text"
                />
                <ui-button
                    label="Před týdnem"
                    (onClick)="applyPreset(-1, 'week')"
                    styleClass="p-button-secondary p-button-text"
                />
                <ui-button
                    label="Minulý měsíc"
                    (onClick)="applyPreset(-1, 'month')"
                    styleClass="p-button-secondary p-button-text"
                />
                <ui-button
                    label="Minulý rok"
                    (onClick)="applyPreset(-1, 'year')"
                    styleClass="p-button-secondary p-button-text"
                />
            }
            @if (mode() === selectionMode.Single && isMinDateGreaterThanToday()) {
                <ui-button
                    label="Dnes"
                    (onClick)="applyPreset(0, 'day')"
                    styleClass="p-button-secondary p-button-text"
                />
                <ui-button
                    label="Zítra"
                    (onClick)="applyPreset(1, 'day')"
                    styleClass="p-button-secondary p-button-text"
                />
                <ui-button
                    label="Za týden"
                    (onClick)="applyPreset(1, 'week')"
                    styleClass="p-button-secondary p-button-text"
                />
                <ui-button
                    label="Za měsíc"
                    (onClick)="applyPreset(1, 'month')"
                    styleClass="p-button-secondary p-button-text"
                />
                <ui-button
                    label="Za rok"
                    (onClick)="applyPreset(1, 'year')"
                    styleClass="p-button-secondary p-button-text"
                />
            }
            @if (mode() === selectionMode.Range) {
                <ui-button
                    label="Dnes - celý týden"
                    (onClick)="applyPreset(0, 'week', false)"
                    styleClass="p-button-secondary p-button-text"
                />
                <ui-button
                    label="Dnes - celý měsíc"
                    (onClick)="applyPreset(0, 'month', false)"
                    styleClass="p-button-secondary p-button-text"
                />
            }
        </p-footer>
    }

    @if (showTimeToggle()) {
        <ng-template #footer>
            @if (showPresets()) {
                <div class="preset-footer"></div>
            }
            <div class="time-toggle">
                <div class="flex justify-end">
                    <div class="m-2.5 inline-grid grid-cols-[minmax(20px,_min-content)_auto] items-center gap-2">
                        <ui-switch
                            inputId="timeToggle"
                            label="Zadat čas"
                            [(ngModel)]="displayTime"
                            mode="inline-end"
                            size="xs"
                            (onChange)="showTimePicker($event)"
                            class="order-1 flex"
                        />
                    </div>
                </div>
            </div>
            <div class="time-footer"></div>
        </ng-template>
    }
</p-datePicker>
<ui-validation-error><ng-content></ng-content></ui-validation-error>
