import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { ToastButtonsConfigurationService, ToastMessage, ToastPositionType } from '@abstractions';
import { animate, animateChild, keyframes, query, state, style, transition, trigger } from '@angular/animations';

import { Clipboard } from '@angular/cdk/clipboard';
import { Router } from '@angular/router';
import { ToastIconComponent } from './toast-icon/toast-icon.component';
import { ToastContentComponent } from './toast-content/toast-content.component';
import { ToastActionsComponent } from './toast-actions/toast-actions.component';
import { ToastButtonsComponent } from './toast-buttons/toast-buttons.component';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'ui-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('messageState', [
      state(
        'visible',
        style({
          transform: 'translateY(0)',
          opacity: 1,
        }),
      ),
      transition(':enter', [
        style({ transform: 'translateY(100%)', opacity: 0 }),
        animate('800ms cubic-bezier(0.35, 0, 0.25, 1)'),
      ]),
      transition(':leave', [
        style({ transform: 'translateY(0)', opacity: 1 }),
        animate(
          '600ms cubic-bezier(0.35, 0, 0.25, 1)',
          keyframes([
            style({ transform: 'translateY(0)', opacity: 1, offset: 0 }),
            style({ transform: 'translateY(10%)', opacity: 1, offset: 0.3 }),
            style({ transform: 'translateY(-100%)', opacity: 0, offset: 1 }),
          ]),
        ),
      ]),
    ]),
    trigger('positionChange', [
      state('right', style({ position: 'absolute', top: '10px', right: '-150%' })),
      state('center', style({ top: '0', right: '0%', position: 'sticky' })),
      transition('center => right', animate('200ms cubic-bezier(0.35, 0, 0.25, 1)')),
      transition('right => center', animate('200ms ease-in-out')),
    ]),
    trigger('timerChange', [transition(':enter, :leave', [query('@*', animateChild())])]),
  ],
  standalone: true,
  imports: [ToastIconComponent, ToastContentComponent, ToastActionsComponent, ToastButtonsComponent, AsyncPipe],
})
export class ToastComponent implements AfterViewInit, OnDestroy {
  @ViewChild('toast') toastEl!: ElementRef;

  @Input() message!: ToastMessage;

  @Output() private readonly onClose = new EventEmitter<string>();

  protected readonly toastPosition = ToastPositionType;
  textCopied = false;
  timeout!: NodeJS.Timeout | null;
  private defaultLife = 3000;

  position = this.toastPosition.Center;
  animationState: 'start' | 'end' | 'reset' = 'start';
  isVisible = true;

  toastButtons$ = this.buttonsConfigurationService.getButtons([
    (message: ToastMessage) => {
      this.onClose.emit(message.id);
    },
  ]);

  constructor(
    private readonly router: Router,
    private readonly clipboard: Clipboard,
    private readonly zone: NgZone,
    private readonly buttonsConfigurationService: ToastButtonsConfigurationService,
    private readonly cd: ChangeDetectorRef,
  ) {}

  ngAfterViewInit(): void {
    this.initTimeout();
  }

  ngOnDestroy(): void {
    this.clearTimeout();
  }

  copyPhoneAndRedirect(): void {
    this.copyPhone();
    if (this.message.data?.routerLink?.length) {
      this.router.navigate(this.message.data.routerLink);
    }
  }

  copyPhone(): void {
    const unformattedPhone = this.message?.detail?.replace(/[\s\u200B\u200C\u200D\uFEFF]+/g, '') ?? '';
    this.clipboard.copy(unformattedPhone);
  }

  copyText(): void {
    this.clipboard.copy(this.message?.detail ?? '');
    this.textCopied = true;
    this.onClose.emit(this.message.id);
  }

  initTimeout(): void {
    if (!this.message.sticky) {
      this.zone.runOutsideAngular(() => {
        this.timeout = setTimeout(() => this.close(), this.message.life || this.defaultLife);
      });
    }
  }

  clearTimeout(): void {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
  }

  onMouseEnter(): void {
    this.animationState = 'reset';
    this.clearTimeout();
  }

  onMouseLeave(): void {
    this.animationTimerStart();
    this.initTimeout();
  }

  get animationDuration(): number {
    if (this.message.sticky) {
      return 0;
    }

    return (this.message.life || this.defaultLife) * 2;
  }

  animationTimerStart(): void {
    this.animationState = 'start';
    this.animationState = 'end';
  }

  close(): void {
    this.clearTimeout();
    this.isVisible = false;
    this.cd.detectChanges();
    setTimeout(() => {
      this.onClose.next(this.message?.id ?? '');
    }, 1000);
  }

  togglePosition(): void {
    this.toastEl.nativeElement.classList.toggle('minimized');
    this.position = this.position === this.toastPosition.Right ? this.toastPosition.Center : this.toastPosition.Right;
  }
}
