import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { CommonMessages } from '@domain';
@Component({
  selector: 'ui-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelComponent {
  label = input<string>();
  inputId = input<string>();
  badge = input<string>();
  badgeStyleClass = input<string>('');
  trailing = input<string | false>();
}
