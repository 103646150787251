import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ToastButton, ToastMessage } from '@abstractions';
import { ButtonComponent } from '../../../primeng/button/button.component';
import { Divider } from 'primeng/divider';

@Component({
  selector: 'ui-toast-buttons',
  templateUrl: './toast-buttons.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ButtonComponent, Divider],
})
export class ToastButtonsComponent {
  @Input() message!: ToastMessage;
  @Input() toastButtons!: ToastButton[];
}
