import { ChangeDetectionStrategy, Component, OnDestroy, Renderer2, inject, input, output } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { AppService } from '@utils';
import { DisplayMode } from './confirm-dialog.enum';

import { DialogModule } from 'primeng/dialog';
import { LoaderComponent } from '../../non-primeng/loader/loader.component';
import { ButtonComponent } from '../button/button.component';
import { SvgIconComponent } from '../../non-primeng/svg-icon/svg-icon.component';

@Component({
  selector: 'ui-confirm-dialog',
  styleUrls: ['./confirm-dialog.component.scss'],
  templateUrl: './confirm-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [DialogModule, LoaderComponent, ButtonComponent, SvgIconComponent],
})
export class ConfirmDialogComponent implements OnDestroy {
  loading = input<boolean>(false);
  storing = input<boolean>(false);
  display = input<boolean>(false);
  header = input<string>('');
  disabled = input<boolean>(false);
  closable = input<boolean>(false);
  focusOnShow = input<boolean>(false);
  mode = input<string>(DisplayMode.DANGER);
  acceptButtonLabel = input<string>('Potvrdit');
  rejectButtonLabel = input<string>('Zavřít');
  styleClass = input<string>('w-[48rem] min-h-[22rem]');

  readonly accept = output<void>();
  readonly reject = output<void>();

  private readonly sanitizer = inject(DomSanitizer);
  private readonly app = inject(AppService);
  private readonly renderer = inject(Renderer2);

  get headerValue(): string {
    return this.header();
  }

  get dialogStyleClass(): string {
    return `p-confirm-dialog p-confirm-dialog-${this.mode()} ${this.styleClass()}`;
  }

  get acceptButtonStyleClass(): string {
    return 'p-button-' + this.mode();
  }

  get acceptButtonIcon(): string {
    let buttonIconName;
    switch (this.mode()) {
      case DisplayMode.DANGER:
        buttonIconName = 'Bin';
        break;
      case DisplayMode.SUCCESS:
        buttonIconName = 'Check Circle';
        break;
      case DisplayMode.WARNING:
        buttonIconName = 'Warning Triangle';
        break;
      case DisplayMode.CANCEL:
        buttonIconName = 'X Mark Circle';
        break;
      default:
        buttonIconName = '';
    }
    return buttonIconName;
  }

  get decorationIcon(): SafeHtml {
    let decorationIcon;
    switch (this.mode()) {
      case DisplayMode.DANGER:
        decorationIcon = `<path fill-rule="evenodd" d="M9.103 3.5a1.5 1.5 0 0 0-1.062.44L3.94 8.043a1.5 1.5 0 0 0-.44 1.06v5.795a1.5 1.5 0 0 0 .44 1.062l4.102 4.1a1.499 1.499 0 0 0 1.06.44h5.796a1.5 1.5 0 0 0 1.061-.44l4.101-4.1a1.499 1.499 0 0 0 .44-1.062V9.103a1.5 1.5 0 0 0-.44-1.062L15.957 3.94a1.5 1.5 0 0 0-1.06-.44H9.102Zm7.916-.621A3 3 0 0 0 14.897 2H9.103a3 3 0 0 0-2.123.88L2.879 6.981A3 3 0 0 0 2 9.103v5.794a3 3 0 0 0 .88 2.123l4.101 4.1a3 3 0 0 0 2.122.879h5.795a3.002 3.002 0 0 0 2.122-.879l4.101-4.101A3 3 0 0 0 22 14.897V9.103a3.003 3.003 0 0 0-.88-2.123l-4.101-4.101ZM12 7.25a.75.75 0 0 1 .75.75v4a.75.75 0 0 1-1.5 0V8a.75.75 0 0 1 .75-.75Zm0 8a.75.75 0 0 0 0 1.5h.01a.75.75 0 0 0 0-1.5H12Z" clip-rule="evenodd"/>`;
        break;
      case DisplayMode.WARNING:
        decorationIcon = `<g><path d="M4.23 21h15.563a2.218 2.218 0 0 0 1.038-.268 2.23 2.23 0 0 0 .794-.729 2.26 2.26 0 0 0 .213-2.09L13.946 4.157a2.236 2.236 0 0 0-.816-.847 2.206 2.206 0 0 0-2.259 0 2.235 2.235 0 0 0-.816.847L2.163 17.912a2.266 2.266 0 0 0 .185 2.051A2.206 2.206 0 0 0 4.109 21"/><path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" d="M12 10v1.997m0 3.993V16"/></g>`;
        break;
      case DisplayMode.CANCEL:
        decorationIcon = `<path d="M9 9L15 15M15 9L9 15M3 12C3 13.1819 3.23279 14.3522 3.68508 15.4442C4.13738 16.5361 4.80031 17.5282 5.63604 18.364C6.47177 19.1997 7.46392 19.8626 8.55585 20.3149C9.64778 20.7672 10.8181 21 12 21C13.1819 21 14.3522 20.7672 15.4442 20.3149C16.5361 19.8626 17.5282 19.1997 18.364 18.364C19.1997 17.5282 19.8626 16.5361 20.3149 15.4442C20.7672 14.3522 21 13.1819 21 12C21 10.8181 20.7672 9.64778 20.3149 8.55585C19.8626 7.46392 19.1997 6.47177 18.364 5.63604C17.5282 4.80031 16.5361 4.13738 15.4442 3.68508C14.3522 3.23279 13.1819 3 12 3C10.8181 3 9.64778 3.23279 8.55585 3.68508C7.46392 4.13738 6.47177 4.80031 5.63604 5.63604C4.80031 6.47177 4.13738 7.46392 3.68508 8.55585C3.23279 9.64778 3 10.8181 3 12Z" stroke-linecap="round" stroke-linejoin="round"/>`;
        break;
      case DisplayMode.NORMAL:
        decorationIcon = `<path d="M3 12C3 13.1819 3.23279 14.3522 3.68508 15.4442C4.13738 16.5361 4.80031 17.5282 5.63604 18.364C6.47177 19.1997 7.46392 19.8626 8.55585 20.3149C9.64778 20.7672 10.8181 21 12 21C13.1819 21 14.3522 20.7672 15.4442 20.3149C16.5361 19.8626 17.5282 19.1997 18.364 18.364C19.1997 17.5282 19.8626 16.5361 20.3149 15.4442C20.7672 14.3522 21 13.1819 21 12C21 10.8181 20.7672 9.64778 20.3149 8.55585C19.8626 7.46392 19.1997 6.47177 18.364 5.63604C17.5282 4.80031 16.5361 4.13738 15.4442 3.68508C14.3522 3.23279 13.1819 3 12 3C10.8181 3 9.64778 3.23279 8.55585 3.68508C7.46392 4.13738 6.47177 4.80031 5.63604 5.63604C4.80031 6.47177 4.13738 7.46392 3.68508 8.55585C3.23279 9.64778 3 10.8181 3 12Z" stroke-linecap="round" stroke-linejoin="round"/><path d="M12 8H12.01" stroke-linecap="round" stroke-linejoin="round"/><path d="M11 12H12V16H13" stroke-linecap="round" stroke-linejoin="round"/>`;
        break;
      case DisplayMode.SUCCESS:
        decorationIcon = `<path d="M3 12C3 13.1819 3.23279 14.3522 3.68508 15.4442C4.13738 16.5361 4.80031 17.5282 5.63604 18.364C6.47177 19.1997 7.46392 19.8626 8.55585 20.3149C9.64778 20.7672 10.8181 21 12 21C13.1819 21 14.3522 20.7672 15.4442 20.3149C16.5361 19.8626 17.5282 19.1997 18.364 18.364C19.1997 17.5282 19.8626 16.5361 20.3149 15.4442C20.7672 14.3522 21 13.1819 21 12C21 10.8181 20.7672 9.64778 20.3149 8.55585C19.8626 7.46392 19.1997 6.47177 18.364 5.63604C17.5282 4.80031 16.5361 4.13738 15.4442 3.68508C14.3522 3.23279 13.1819 3 12 3C10.8181 3 9.64778 3.23279 8.55585 3.68508C7.46392 4.13738 6.47177 4.80031 5.63604 5.63604C4.80031 6.47177 4.13738 7.46392 3.68508 8.55585C3.23279 9.64778 3 10.8181 3 12Z" stroke-linecap="round" stroke-linejoin="round"/><path d="M12 8H12.01" stroke-linecap="round" stroke-linejoin="round"/><path d="M11 12H12V16H13" stroke-linecap="round" stroke-linejoin="round"/>`;
        break;
    }

    return this.sanitizer.bypassSecurityTrustHtml(decorationIcon ?? '');
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'ui-confirm-dialog--active');
  }

  onAccept(): void {
    this.handleApplicationScrollBar();
    this.accept.emit();
  }

  onReject(): void {
    this.handleApplicationScrollBar();
    this.reject.emit();
  }

  onHide(): void {
    this.handleApplicationScrollBar();
  }

  onShow(): void {
    this.renderer.addClass(document.body, 'ui-confirm-dialog--active');
    this.app.hideBodyScrollBar();
  }

  private handleApplicationScrollBar(): void {
    if (document.querySelector('ui-dialog')) {
      return;
    }
    this.app.showBodyScrollBar();
  }
}
