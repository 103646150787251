import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { ProgressSpinner } from 'primeng/progressspinner';
import { NgClass } from '@angular/common';

// jelly =  https://uiverse.io/G4b413l/kind-cougar-54

@Component({
  selector: 'ui-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ProgressSpinner, NgClass],
})
export class LoaderComponent {
  styleClass = input<string>();
  variant = input<'default' | 'jelly' | 'ring'>('jelly');
  message = input<string>();
}
