@if (label()) {
    <ui-label [inputId]="inputId()" [label]="label()" (click)="onLabelClick(select)" [trailing]="labelTrailing()" />
}
<p-select
    #select
    [id]="inputId()"
    [appendTo]="appendTo()"
    [autoDisplayFirst]="autoDisplayFirst()"
    [autofocus]="autofocus()"
    [autofocusFilter]="autofocusFilter()"
    [disabled]="isDisabled()"
    [emptyFilterMessage]="emptyFilterMessage()"
    [emptyMessage]="emptyMessage()"
    [filter]="filter()"
    [filterBy]="filterBy()"
    [filterFields]="filterFields()"
    [filterMatchMode]="filterMatchMode()"
    [filterPlaceholder]="filterPlaceholder()"
    [filterValue]="filterValue()"
    [group]="group()"
    [fluid]="fluid()"
    [inputId]="inputId()"
    [lazy]="lazy()"
    [loading]="loading()"
    [name]="name()"
    [options]="options()"
    [optionDisabled]="optionDisabled()"
    [optionLabel]="optionLabel()"
    [optionValue]="optionValue()"
    [panelStyleClass]="panelStyleClass()"
    [placeholder]="placeholder()"
    [readonly]="readonly()"
    [resetFilterOnHide]="resetFilterOnHide()"
    [scrollHeight]="scrollHeight()"
    [showClear]="showClear()"
    [styleClass]="styleClass()"
    [tooltip]="tooltip()"
    [tooltipPosition]="tooltipPosition()"
    [virtualScroll]="virtualScroll()"
    [virtualScrollItemSize]="virtualScrollItemSize()"
    (onBlur)="onSelectBlur()"
    (onChange)="onSelectChange($event)"
    (onClear)="onSelectClear($event)"
    (onFilter)="onSelectFilter($event)"
    (onFocus)="onSelectFocus($event)"
    (onHide)="onSelectHide()"
>
    <ng-template #dropdownicon>
        <ui-svg icon="Chevron Down" />
    </ng-template>
    <ng-template #filtericon>
        <ui-svg icon="Magnifier" mode="fill" fillColor="fill-gray-400" />
    </ng-template>
    <ng-template #clearicon>
        <ui-svg icon="X Mark" (click)="onSelectClear($event)" />
    </ng-template>
    <ng-template #loader>
        <ui-loader variant="ring" />
    </ng-template>
    @if (itemTemplate) {
        <ng-template #item let-option>
            <ng-container *ngTemplateOutlet="itemTemplate || null; context: { $implicit: option }"></ng-container>
        </ng-template>
    }
    @if (selectedItemTemplate) {
        <ng-template #selectedItem let-selectedOption>
            <ng-container
                *ngTemplateOutlet="selectedItemTemplate || null; context: { $implicit: selectedOption }"
            ></ng-container>
        </ng-template>
    }
    @if (groupTemplate) {
        <ng-template #group let-group>
            <ng-container *ngTemplateOutlet="groupTemplate || null; context: { $implicit: group }"></ng-container>
        </ng-template>
    }
</p-select>
