import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ToastMessage, ToastType } from '@abstractions';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Tooltip } from 'primeng/tooltip';

@Component({
  selector: 'ui-toast-icon',
  templateUrl: './toast-icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('timerChange', [
      state('start', style({ strokeDasharray: '0,628' })),
      state('end', style({ strokeDasharray: '628,628' })),
      state('reset', style({ strokeDasharray: '0,628' })),
      transition('start => end', [animate('{{duration}}ms')]),
      transition('reset => *', [animate('{{duration}}ms'), style({ opacity: 0 })]),
    ]),
  ],
  standalone: true,
  imports: [Tooltip],
})
export class ToastIconComponent {
  @Input() message: ToastMessage;
  @Input() animationDuration: number;
  @Input() animationState: 'start' | 'end' | 'reset';

  public readonly toastType = ToastType;
  public duration;
}
