import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
  Renderer2,
  TemplateRef,
  input,
  output,
} from '@angular/core';

import { AppService } from '@utils';

@Component({
  selector: 'ui-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogComponent implements OnDestroy {
  display = input<boolean>(false);
  visible = input<boolean>(false);
  modal = input<boolean>(true);
  blockScroll = input<boolean>(false);
  disabled = input<boolean>(false);
  blocked = input<boolean>(false);
  closeOnEsc = input<boolean>(true);
  focusOnShow = input<boolean>(false);
  closable = input<boolean>(true);
  maximizable = input<boolean>(false);
  resizable = input<boolean>(false);
  draggable = input<boolean>(false);
  loading = input<boolean>(false);
  storing = input<boolean>(false);
  header = input<string>('');
  showAcceptButton = input<boolean>(true);
  acceptButtonLabel = input<string>('Potvrdit');
  acceptButtonTooltip = input<string>();
  showRejectButton = input<boolean>(true);
  rejectButtonLabel = input<string>('Zavřít');
  styleClass = input<string>('min-w-[25vw]');
  contentClass = input<string>();
  maskClass = input<string>();
  appendTo = input<HTMLElement | ElementRef | TemplateRef<any> | string>();
  position = input<'center' | 'top'>('center');

  readonly accept = output<void>();
  readonly reject = output<void>();
  readonly appear = output<void>();
  readonly disappear = output<void>();
  readonly permanent = output<boolean>();

  constructor(
    private app: AppService,
    private renderer: Renderer2,
  ) {}

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'ui-dialog--active');
    this.onDisappear();
  }

  onAccept(): void {
    this.accept.emit();
  }

  onReject(): void {
    this.reject.emit();
  }

  onAppear(): void {
    this.app.hideBodyScrollBar();
    this.renderer.addClass(document.body, 'ui-dialog--active');
    this.appear.emit();
  }

  onDisappear(): void {
    this.app.showBodyScrollBar();
    this.disappear.emit();
  }

  get headerValue(): string {
    return this.header();
  }
}
