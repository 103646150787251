<div class="text-content flex flex-1 flex-col justify-center" [ngClass]="{ 'mr-4': !toastType.Error }">
    @if (message.summary) {
        <div class="whitespace-nowrap font-bold" [innerHTML]="message.summary"></div>
    }

    @switch (message.data?.code) {
        @case (messageType.IncomingCall) {
            <div class="flex items-center gap-x-1">
                <a
                    (click)="copyPhoneAndRedirect()"
                    [routerLink]="message.data?.routerLink"
                    class="font-semibold text-gray-900"
                >
                    {{ message.detail }}
                </a>
                <ui-svg
                    icon="Copy"
                    size="xs"
                    strokeColor="stroke-transparent"
                    fillColor="fill-gray-400"
                    pTooltip="Zkopírovat do schránky"
                    tooltipPosition="right"
                    tooltipZIndex="2000"
                    class="cursor-pointer"
                    [showDelay]="500"
                    (click)="copyPhone()"
                />
            </div>
        }
        @case (messageType.Fishtag) {
            <div class="flex items-center gap-x-1">
                @if (!textCopied) {
                    <div class="whitespace-nowrap font-medium text-red-300">Fishtag:</div>
                    <a
                        class="break-all font-medium"
                        pTooltip="Zkopírovat fishtag do schránky"
                        tooltipPosition="bottom"
                        [showDelay]="1500"
                        (click)="copyText()"
                    >
                        {{ message.detail }}
                        <svg width="14" height="14" viewBox="0 0 18 18" (click)="copyText()" class="min-w-4">
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M3 0.25C2.27065 0.25 1.57118 0.539731 1.05546 1.05546C0.539731 1.57118 0.25 2.27065 0.25 3V11C0.25 11.7293 0.539731 12.4288 1.05546 12.9445C1.57118 13.4603 2.27065 13.75 3 13.75H4.25V15C4.25 15.7293 4.53973 16.4288 5.05546 16.9445C5.57118 17.4603 6.27065 17.75 7 17.75H15C15.7293 17.75 16.4288 17.4603 16.9445 16.9445C17.4603 16.4288 17.75 15.7293 17.75 15V7C17.75 6.27065 17.4603 5.57118 16.9445 5.05546C16.4288 4.53973 15.7293 4.25 15 4.25H13.75V3C13.75 2.27065 13.4603 1.57118 12.9445 1.05546C12.4288 0.539731 11.7293 0.25 11 0.25H3ZM12.25 4.25V3C12.25 2.66848 12.1183 2.35054 11.8839 2.11612C11.6495 1.8817 11.3315 1.75 11 1.75H3C2.66848 1.75 2.35054 1.8817 2.11612 2.11612C1.8817 2.35054 1.75 2.66848 1.75 3V11C1.75 11.3315 1.8817 11.6495 2.11612 11.8839C2.35054 12.1183 2.66848 12.25 3 12.25H4.25V7C4.25 6.27065 4.53973 5.57118 5.05546 5.05546C5.57118 4.53973 6.27065 4.25 7 4.25H12.25ZM5.75 13V15C5.75 15.3315 5.8817 15.6495 6.11612 15.8839C6.35054 16.1183 6.66848 16.25 7 16.25H15C15.3315 16.25 15.6495 16.1183 15.8839 15.8839C16.1183 15.6495 16.25 15.3315 16.25 15V7C16.25 6.66848 16.1183 6.35054 15.8839 6.11612C15.6495 5.8817 15.3315 5.75 15 5.75H13H7C6.66848 5.75 6.35054 5.8817 6.11612 6.11612C5.8817 6.35054 5.75 6.66848 5.75 7V13Z"
                            />
                        </svg>
                    </a>
                } @else {
                    Kód chyby zkopírován.
                }
            </div>
        }
        @case (messageType.TaskCountChange) {
            <a [routerLink]="message.data?.routerLink">{{ message.detail }}</a>
        }
        @case (messageType.IpexTicket) {
            <div class="flex gap-x-1">
                @if (!textCopied) {
                    <div>Ticket:</div>
                    @if (message.data?.link) {
                        <a
                            class="flex"
                            [href]="envConf.ipexUrl + message.detail"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            {{ message.detail }}
                            <ui-svg
                                icon="External Link"
                                size="xs"
                                class="ml-1 self-baseline"
                                strokeColor="stroke-gray-900"
                                fillColor="fill-transparent"
                            />
                        </a>
                    } @else {
                        <a class="break-all" (click)="copyText()">
                            {{ message.detail }}
                            <svg width="14" height="14" viewBox="0 0 18 18" (click)="copyText()">
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M3 0.25C2.27065 0.25 1.57118 0.539731 1.05546 1.05546C0.539731 1.57118 0.25 2.27065 0.25 3V11C0.25 11.7293 0.539731 12.4288 1.05546 12.9445C1.57118 13.4603 2.27065 13.75 3 13.75H4.25V15C4.25 15.7293 4.53973 16.4288 5.05546 16.9445C5.57118 17.4603 6.27065 17.75 7 17.75H15C15.7293 17.75 16.4288 17.4603 16.9445 16.9445C17.4603 16.4288 17.75 15.7293 17.75 15V7C17.75 6.27065 17.4603 5.57118 16.9445 5.05546C16.4288 4.53973 15.7293 4.25 15 4.25H13.75V3C13.75 2.27065 13.4603 1.57118 12.9445 1.05546C12.4288 0.539731 11.7293 0.25 11 0.25H3ZM12.25 4.25V3C12.25 2.66848 12.1183 2.35054 11.8839 2.11612C11.6495 1.8817 11.3315 1.75 11 1.75H3C2.66848 1.75 2.35054 1.8817 2.11612 2.11612C1.8817 2.35054 1.75 2.66848 1.75 3V11C1.75 11.3315 1.8817 11.6495 2.11612 11.8839C2.35054 12.1183 2.66848 12.25 3 12.25H4.25V7C4.25 6.27065 4.53973 5.57118 5.05546 5.05546C5.57118 4.53973 6.27065 4.25 7 4.25H12.25ZM5.75 13V15C5.75 15.3315 5.8817 15.6495 6.11612 15.8839C6.35054 16.1183 6.66848 16.25 7 16.25H15C15.3315 16.25 15.6495 16.1183 15.8839 15.8839C16.1183 15.6495 16.25 15.3315 16.25 15V7C16.25 6.66848 16.1183 6.35054 15.8839 6.11612C15.6495 5.8817 15.3315 5.75 15 5.75H13H7C6.66848 5.75 6.35054 5.8817 6.11612 6.11612C5.8817 6.35054 5.75 6.66848 5.75 7V13Z"
                                    class="fill-red-100"
                                />
                            </svg>
                        </a>
                    }
                    <ng-template #text>
                        <a class="break-all" (click)="copyText()">
                            {{ message.detail }}
                            <svg width="14" height="14" viewBox="0 0 18 18" (click)="copyText()">
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M3 0.25C2.27065 0.25 1.57118 0.539731 1.05546 1.05546C0.539731 1.57118 0.25 2.27065 0.25 3V11C0.25 11.7293 0.539731 12.4288 1.05546 12.9445C1.57118 13.4603 2.27065 13.75 3 13.75H4.25V15C4.25 15.7293 4.53973 16.4288 5.05546 16.9445C5.57118 17.4603 6.27065 17.75 7 17.75H15C15.7293 17.75 16.4288 17.4603 16.9445 16.9445C17.4603 16.4288 17.75 15.7293 17.75 15V7C17.75 6.27065 17.4603 5.57118 16.9445 5.05546C16.4288 4.53973 15.7293 4.25 15 4.25H13.75V3C13.75 2.27065 13.4603 1.57118 12.9445 1.05546C12.4288 0.539731 11.7293 0.25 11 0.25H3ZM12.25 4.25V3C12.25 2.66848 12.1183 2.35054 11.8839 2.11612C11.6495 1.8817 11.3315 1.75 11 1.75H3C2.66848 1.75 2.35054 1.8817 2.11612 2.11612C1.8817 2.35054 1.75 2.66848 1.75 3V11C1.75 11.3315 1.8817 11.6495 2.11612 11.8839C2.35054 12.1183 2.66848 12.25 3 12.25H4.25V7C4.25 6.27065 4.53973 5.57118 5.05546 5.05546C5.57118 4.53973 6.27065 4.25 7 4.25H12.25ZM5.75 13V15C5.75 15.3315 5.8817 15.6495 6.11612 15.8839C6.35054 16.1183 6.66848 16.25 7 16.25H15C15.3315 16.25 15.6495 16.1183 15.8839 15.8839C16.1183 15.6495 16.25 15.3315 16.25 15V7C16.25 6.66848 16.1183 6.35054 15.8839 6.11612C15.6495 5.8817 15.3315 5.75 15 5.75H13H7C6.66848 5.75 6.35054 5.8817 6.11612 6.11612C5.8817 6.35054 5.75 6.66848 5.75 7V13Z"
                                    class="fill-red-100"
                                />
                            </svg>
                        </a>
                    </ng-template>
                } @else {
                    Ticket zkopírován.
                }
            </div>
        }
        @default {
            <div class="flex-column">
                @if (message.detail) {
                    @if (message.severity === toastType.Error) {
                        <div>
                            <code
                                [innerHTML]="message.detail"
                                class="mt-1 block w-full bg-white/10 box-decoration-clone text-xs"
                            ></code>
                        </div>
                    } @else {
                        <div [innerHTML]="message.detail"></div>
                    }
                }
            </div>
        }
    }
</div>
