import { AfterViewInit, ChangeDetectionStrategy, Component, input, output, signal, viewChild } from '@angular/core';
import { RadioButton, RadioButtonClickEvent, RadioButtonStyle } from 'primeng/radiobutton';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Tooltip } from 'primeng/tooltip';

@Component({
  selector: 'ui-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrl: './radio-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: RadioButtonComponent,
    },
    RadioButtonStyle,
  ],
  imports: [RadioButton, Tooltip],
})
export class RadioButtonComponent implements ControlValueAccessor, AfterViewInit {
  binary = input(false);
  formControlName = input<string>('');
  inputId = input.required<string>();
  label = input<string>();
  name = input<string>();
  styleClass = input<string>();
  tooltip = input('');
  tooltipPosition = input<'top' | 'left' | 'right' | 'bottom'>('top');
  value = input<any>();

  onClick = output<RadioButtonClickEvent>();
  onFocus = output<Event>();

  radioButton = viewChild.required<RadioButton>('radioButton');

  onChanged: (value: unknown) => void = () => {};
  onTouched: VoidFunction = () => {};
  tmpValue: any;

  isDisabled = signal(false);

  ngAfterViewInit(): void {
    this.writeValue(this.tmpValue);
    this.tmpValue = null;
  }

  registerOnChange(fn: (value: unknown) => void): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: VoidFunction): void {
    this.onTouched = fn;
  }

  writeValue(value: unknown): void {
    this.radioButton().writeValue(value);
    this.tmpValue = value;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled.set(isDisabled);
  }

  onRadioButtonClick(event: RadioButtonClickEvent): void {
    this.onChanged(event.value);
    this.onClick.emit(event);
  }

  onRadioButtonFocus(event: Event): void {
    this.onFocus.emit(event);
  }

  onRadioButtonBlur(): void {
    this.onTouched();
  }
}
