import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ToastMessage, ToastMessageType, ToastPositionType } from '@abstractions';
import { ButtonComponent } from '../../../primeng/button/button.component';
import { Tooltip } from 'primeng/tooltip';

@Component({
  selector: 'ui-toast-actions',
  templateUrl: './toast-actions.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ButtonComponent, Tooltip],
})
export class ToastActionsComponent {
  protected readonly messageType = ToastMessageType;
  protected readonly positionType = ToastPositionType;

  @Input() message!: ToastMessage;
  @Input() position!: ToastPositionType;

  @Output() private readonly onClose = new EventEmitter<void>();
  @Output() private readonly onTogglePosition = new EventEmitter<void>();

  public close(): void {
    this.onClose.emit();
  }

  public togglePosition(): void {
    this.onTogglePosition.emit();
  }
}
