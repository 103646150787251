import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
  model,
  output,
  signal,
  viewChild,
} from '@angular/core';
import { ControlValueAccessor, FormsModule, NgControl, Validators } from '@angular/forms';
import { SelectionMode, SelectionModeType } from './calendar.enum';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

import { DateTimeFormat } from '@domain';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { dateEqualsBy } from '@utils';
import dayjs from 'dayjs';
import { fromEvent } from 'rxjs';
import { DatePicker } from 'primeng/datepicker';
import { twMerge } from 'tailwind-merge';
import { ButtonComponent } from '../button/button.component';
import { CalendarModule } from 'primeng/calendar';
import { SvgIconComponent } from '../../non-primeng/svg-icon/svg-icon.component';
import { ValidationErrorComponent } from '../../non-primeng/validation-message/validation-message.component';
import { SwitchModule } from '../switch/switch.module';
import { LabelModule } from '../label/label.module';

dayjs.extend(customParseFormat);

@Component({
  selector: 'ui-calendar',
  styleUrls: ['./calendar.components.scss'],
  templateUrl: './calendar.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ButtonComponent,
    CalendarModule,
    FormsModule,
    DatePicker,
    SvgIconComponent,
    ValidationErrorComponent,
    SwitchModule,
    LabelModule,
  ],
})
export class CalendarComponent implements AfterViewInit, ControlValueAccessor {
  inputId = input<string>();
  label = input<string>();
  name = input<string>();
  appendTo = input<string>('body');
  months = input<number>(1);
  minDate = input<Date | undefined>(undefined);
  maxDate = input<Date>();
  placeholder = input<string>();
  labelTrailing = input<string>();
  fluid = input<boolean>(true);
  disabled = input<boolean>(false);
  mode = input<SelectionModeType>(SelectionMode.Single);
  readonly = input<boolean>(false);
  styleClass = input<string>('');
  panelStyle = input<any>('');
  panelStyleClass = input<string>('');
  showOtherMonths = input<boolean>(true);
  showTime = input<boolean>(false);
  showTimeToggle = input<boolean>(false);
  showPresets = input<boolean>(false);
  showClear = input<boolean>(false);
  customPresets = input<boolean>(false);
  showIcon = input<boolean>(true);
  keepInvalid = input<boolean>(true);
  preselectedDate = input<Date>(dayjs().add(1, 'minutes').toDate());
  selectOtherMonths = input<boolean>(true);
  selectedDate = input<Date | string>();
  selectedDateRange = input<Date[]>();

  classes = computed(() => {
    return twMerge('ui-calendar', this.styleClass());
  });
  panelClasses = computed(() => {
    return twMerge('ui-calendar-datepicker', this.panelStyleClass());
  });

  isDisabled = signal(false);
  displayTime = false;

  private _selectedDate = signal<Date | string>(this.selectedDate() ?? '');
  private _selectedDateRange = signal<Date[]>(this.selectedDateRange() ?? []);
  private _comparisonUnit!: dayjs.UnitType;
  private _dateFormats!: DateTimeFormat[];
  private _control = inject(NgControl, { optional: true });

  readonly today = dayjs().add(-1, 'day').endOf('day').toDate();
  readonly selectionMode = SelectionMode;

  readonly dateSelect = output<DatePicker>();
  readonly calendarClose = output<DatePicker>();
  readonly calendarClear = output<DatePicker>();
  readonly formatChange = output<{ displayTime: boolean }>();

  onChanged: (date: Date) => void = () => {};
  onTouched: VoidFunction = () => {};

  calendarRef = viewChild.required<DatePicker>('calendar');

  constructor() {
    if (this._control) {
      this._control.valueAccessor = this;
    }
  }

  ngAfterViewInit(): void {
    this.displayTime = this.showTime();

    if (this._selectedDate()) {
      this.calendarRef().writeValue(this._selectedDate());
    }
    this._comparisonUnit = this.showTime() ? 'minute' : 'day';
    this.updateDateTimeFormats();
  }

  showTimePicker(displayTime: boolean): void {
    this.displayTime = displayTime;
    this.updateDateTimeFormats();
    this.updateSize();

    let date = dayjs(this._selectedDate());
    // previous input was invalid, use calendar value
    if (!date.isValid()) {
      date = dayjs(this.calendarRef()?.inputfieldViewChild?.nativeElement.value.toString(), this._dateFormats, true);
    }
    if (!displayTime) {
      date = date.startOf('day');
    }

    this.setValue(date.toDate());
    if (date.isValid()) {
      this.writeValue(date.toDate());
    }
  }

  registerOnChange(fn: (date: Date) => void): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: VoidFunction): void {
    this.onTouched = fn;
  }

  setValue(date: Date): void {
    this.onChanged(date);
    this._selectedDate.set(date);
  }

  writeValue(date: Date): void {
    this.calendarRef()?.writeValue(date);
    if (Array.isArray(date)) {
      this._selectedDateRange.set(date);
    }
    this._selectedDate.set(date);
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled.set(isDisabled);
  }

  onMonthYearChange(): void {
    this.updateSize();
  }

  onManualInput(event: InputEvent): void {
    fromEvent(this.calendarRef().el.nativeElement, 'input')
      .pipe(
        // @ts-ignore
        map((event: InputEvent) => (event.target as HTMLInputElement).value),
        debounceTime(1500),
        distinctUntilChanged(),
      )
      .subscribe((value) => {
        let date = dayjs(value, this._dateFormats, true);

        if (value === '' && !this._control?.control?.hasValidator(Validators.required)) {
          // @ts-ignore
          this.setValue(null);
        } else {
          this.setValue(date.toDate());
          this.calendarRef()?.cd.markForCheck();
        }

        if (date.isValid()) {
          this.writeValue(date.toDate());
          this.dateSelect.emit(this.calendarRef());
        }
      });

    if (event.inputType === 'insertFromPaste') {
      this._selectedDate.set(dayjs((event.target as HTMLInputElement).value, this._dateFormats, true).toDate());
      this.dateSelect.emit(this.calendarRef());
      this.calendarRef()?.inputfieldViewChild?.nativeElement.blur();
      this.hide();
    }

    if (event?.data?.length) {
      this.hide();
    }
  }

  applyPreset(amount: number = 1, period: dayjs.ManipulateType, hide: boolean = true): void {
    let d;
    if (this.mode() === SelectionMode.Range) {
      const f = dayjs().endOf('day').toDate();
      const t = dayjs().add(1, period).endOf('day').toDate();
      d = [f, t];
    }

    if (this.mode() === SelectionMode.Single) {
      const shiftMinutes = this.minDate() ? 1 : 0;
      d = dayjs().add(amount, period).add(shiftMinutes, 'minutes').toDate();
    }

    // @ts-ignore
    this.writeValue(d);
    // @ts-ignore
    this.setValue(d);

    if (hide) {
      this.hide();
    }
  }

  onClose(calendar?: DatePicker): void {
    if (calendar) {
      if (calendar) {
        this.calendarClose.emit(calendar);
      }
    }
  }

  onClear(calendar?: DatePicker): void {
    if (calendar) {
      this.calendarClear.emit(calendar);
    }
  }

  onSelect(calendar?: DatePicker): void {
    this.writeValue(calendar?.value);
    this.setValue(calendar?.value);
    if (calendar) {
      this.dateSelect.emit(calendar);
    }
  }

  onBlur(calendar?: DatePicker): void {
    this.onTouched();
    const value = calendar?.inputfieldViewChild?.nativeElement.value.toString();
    if (this._selectedDate() && !dateEqualsBy(value, this._selectedDate(), this._comparisonUnit)) {
      this.updateDateTimeFormats();
      let date = dayjs(value, this._dateFormats, true).toDate();

      if (value === '' && !this._control?.control?.hasValidator(Validators.required)) {
        // @ts-ignore
        date = null;
      }

      this.setValue(date);
    }
    if (calendar) {
      this.calendarClose.emit(calendar);
    }
    calendar?.inputfieldViewChild?.nativeElement.blur();
  }

  private updateDateTimeFormats(): void {
    this._dateFormats = this.displayTime
      ? [
          DateTimeFormat.DateTimeFullHoursDayJS,
          DateTimeFormat.DateTimeShortHoursDayJS,
          DateTimeFormat.DateTimeShortFullHoursDayJS,
          DateTimeFormat.DateTimeShortShortHoursDayJS,
        ]
      : [DateTimeFormat.DateDayJS, DateTimeFormat.DateShortDayJS];
    this.formatChange.emit({
      displayTime: this.displayTime,
    });
  }

  isMinDateLesserThanToday(): boolean {
    const minDate = this.minDate();
    return minDate !== undefined && minDate < this.today;
  }

  isMinDateGreaterThanToday(): boolean {
    const minDate = this.minDate();
    return minDate !== undefined && minDate > this.today;
  }

  isTodayLesserThanMaxDate(): boolean {
    const maxDate = this.maxDate();
    return maxDate !== undefined && this.today < maxDate;
  }

  private updateSize(): void {
    if (this.calendarRef().overlay?.style) {
      // @ts-ignore
      this.calendarRef().overlay.style.width = 'max-content';
    }
  }

  private hide(): void {
    this.calendarRef().hideOverlay();
  }
}
